import React, { FC } from 'react';
import WalmartLayout from '../../../components/PagesLayouts//WalmartLayout';
import { TText } from '../../../types/types';
import SectionTitle from '../../../components/common/SectionTitle';
import Links from '../../../components/pagesComponents/CaseStudy/Links';
import FixedButton from '../../../components/common/FixedButton';
import ListItem from '../../../components/pagesComponents/CaseStudy/ListItem';

const OtherIndustriesRelevance: FC = () => {
    const industriesText: TText = [
        {
            id: 0,
            text: 'Custom internal web tools for managing the different type of content.',
            margin: 'mt',
        },
        { id: 1, text: 'Automatization and efficiency optimization of the operations processes.' },
        { id: 2, text: 'Integrations with the advertising platforms.' },
    ];

    return (
        <WalmartLayout>
            <div className="statflo-case-study__text statflo-case-study__text--large">
                <SectionTitle type="primary" title="Others industries relevance" />
                {industriesText.map((industry) => (
                    <ListItem key={industry.id} margin={industry.margin}>
                        {industry.text}
                    </ListItem>
                ))}

                <Links />
            </div>
            <FixedButton/>
        </WalmartLayout>
    );
};

export default OtherIndustriesRelevance;
